import { useContext, useEffect, useMemo } from "react";
import { Col, Container, Row, Accordion, useAccordionToggle, AccordionContext } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Helmet } from "react-helmet";
import MDEditor from "@uiw/react-md-editor";
import texts from "../texts";
import TextOnImage from "../components/TextOnImage";
import faq_photo from "../images/faq_photo.jpg";
import { AppState, FaqModel } from "../redux/types";
import { getFaqsRequest } from "../redux/actions";

const FAQPage = () => {
    const dispatch = useDispatch();
    
    useEffect(() => {
        dispatch(getFaqsRequest());
    }, [dispatch]);

    const _faqs = useSelector((state: AppState) => state.faqs.faqs);

    const faqs = useMemo(() => {
        return _faqs.reduce<Record<string, FaqModel[]>>((group, item) => {
            if (!group[item.category.name]) {
                group[item.category.name] = [];
            }
            group[item.category.name].push(item);
            return group;
        }, {});
    }, [_faqs]);

    function CustomToggle({ children, eventKey } : { children: any, eventKey: string }) {
        const context = useContext(AccordionContext);
        const decoratedOnClick = useAccordionToggle(eventKey, () => {});

        const isActive = context === eventKey;
      
        return (
            <div className="d-flex justify-content-between w-100 btn-header-link" onClick={decoratedOnClick}>
                <div>{children}</div>
                <div>{isActive ? <i className="fas fa-chevron-up" /> : <i className="fas fa-chevron-down" />}</div>
            </div>
        );
    }

    return (
        <>
            <Helmet>
                <title>Usein kysytyt kysymykset. Lue lisää.</title>
                <meta name="description" content="Usein kysytyt kysymykset. Palveluiden ostaminen ja tilaaminen. Lisätietoa palveluista." />
            </Helmet>
            <TextOnImage
                height={`calc(100vh - 66px)`}
                width="100vw"
                text1={texts[12].text1}
                text2={texts[12].text2}
                text21={texts[12].text21}
                text22={texts[12].text22}
                backgroundImage={faq_photo}
                backgroundSize="cover"
                backgroundPosition="center center"
                backgroundRepeat="no-repeat"
                imgClassName="forcompaniespic"
                className1="faq-text-on-image col-md-4 col-12"
                className2="faq-text-on-image col-md-4 col-12 faq-text-on-image-second"
            />
            <Container fluid>
                <Row className="justify-content-md-center" data-color-mode="light">
                    <Col xs={12}>
                        <div className="container_faq">
                            <Accordion>
                                {Object.entries(faqs).map(([category, items], index) => (
                                    <div className="accordion-section" key={category}>
                                        <CustomToggle eventKey={`${index}`}>{category}</CustomToggle>
                                        <Accordion.Collapse eventKey={`${index}`}>
                                            <div className="px-5">
                                                {!!items &&
                                                    items.length > 0 &&
                                                    items.map((faq) => (
                                                        <div key={faq.id} className="mb-3">
                                                            <MDEditor.Markdown source={faq.content} />
                                                        </div>
                                                    ))}
                                            </div>
                                        </Accordion.Collapse>
                                    </div>
                                ))}
                            </Accordion>
                        </div>
                    </Col>
                </Row>
            </Container>
        </>
    );
};

export default FAQPage;
