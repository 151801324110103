import { useEffect, useMemo } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Col, Row } from "react-bootstrap";
import { Helmet } from "react-helmet";
import ArticleFeatured from "../components/ArticleFeatured";
import ArticleItem from "../components/ArticleItem";
import SubNavBar from '../components/SubNavBar'
import { AppState } from "../redux/types";
import { getBlogsRequest } from "../redux/actions";

const Health = () => {
    const dispatch = useDispatch()
    const history = useHistory()
    useEffect(() => {
        dispatch(getBlogsRequest())
        return () => {
        }
    }, [dispatch, history])

    const _blogs = useSelector((state: AppState) => state.blogs.blogs);

    const blogs = useMemo(() => {
        return [..._blogs].sort((a, b) => a.orderValue - b.orderValue);
    }, [_blogs]);

    const featured_article = blogs.find(el => el.featured) || blogs[0] || {
        id: null,
        url: "",
        title: "",
        content: "",
        contentPreview: "",
        coverType: "Image",
        cover: "",
        createdAt: "",
        createdBy: "admin ",
        featured: false,
        orderValue: 0,
        publishedAt: "",
        topics: null,
        updatedAt: "",
    };

    return (
        <>
            <Helmet>
                <meta
                    name="description"
                    content="Blogi seniorin terveydestä ja hyvinvoinnista. Ja vinkkejä iloiseen tabletin käyttöön. Lääketieteen, hyvinvoinnin ja digin ammattilaiset kirjoittajina."
                />
            </Helmet>
            <SubNavBar/>
            <div className="blog-wrapper">
            </div>
            {!!featured_article.id && <ArticleFeatured
                featured_article={featured_article}
            />}
            <div className="blog-wrapper">
                <Row className="blog-items">
                    {blogs?.filter(el => el.id !== featured_article.id)?.map(item => {
                        return <Col
                            md={4}
                            style={{
                                marginBottom: 48
                            }}
                            key={`col_article_${item?.id}`}
                        >
                            <ArticleItem
                                item={item}
                            />
                        </Col>
                    })}
                </Row>
            </div>
        </>
    )
}

export default Health
