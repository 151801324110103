import { put, select, takeLatest } from "redux-saga/effects";

import { getFaqsFail, getFaqsSuccess } from "../actions";
import { GET_FAQS_REQUEST, CMSFaqType, FaqModel } from "../types";
import cmsApi from "../../utils/api-helper/cmsApi";

function* getFaqsSaga() {
    try {
        //@ts-ignore
        const res = yield cmsApi.get("/faqs?populate[Category][fields]");
        const faqs = (res.data.data as CMSFaqType[]).map((faq) => ({
            id: faq.id,
            category: {
                id: faq.attributes.Category.data.id,
                name: faq.attributes.Category.data.attributes.Name,
                slug: faq.attributes.Category.data.attributes.slug,
            },
            content: faq.attributes.Content as any,
        } as FaqModel));
        yield put(getFaqsSuccess(faqs));
    } catch (error) {
        yield put(getFaqsFail(error));
    }
}

// for admin

function* saveState() {
    //@ts-ignore
    const state = yield select();
    yield localStorage.setItem("initState", JSON.stringify(state));
}

const sagaWatcher = [takeLatest(GET_FAQS_REQUEST, getFaqsSaga), takeLatest("*", saveState)];

export default sagaWatcher;
